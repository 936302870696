import { useSiteURL } from "../../base/site"
import { jsonThumbnails } from "../image"

export function jsonTopic({
    path,
    title,
}: {
    path: string
    title: string
    publishedAt: string
    updatedAt: string
}) {
    const url = useSiteURL(path)

    return {
        "@context": "https://schema.org",
        "@type": "DefinedTerm",
        mainEntityOfPage: {
            "@type": "WebPage",
            "@id": url,
            url,
        },
        image: jsonThumbnails(),
        name: title,
        url,
    }
}
