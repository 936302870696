import React from "react"
import { graphql, PageProps } from "gatsby"
import { Kind } from "../../../common/kind"
import { TopicSinglePageQuery } from "../../../types/gatsby-graphql.generated"
import { compareByDate } from "../../base/date"
import { localizeList, localizePlural } from "../../i18n/kind"
import { jsonBreadcrumbListForSinglePage } from "../../json/breadcrumb"
import { jsonTopic } from "../../json/content/topic"
import { SinglePageHeadLayout, SinglePageLayout } from "../../layouts/single"
import {
    ContentHeader,
    LabelGroup,
    KindLabel,
    InfoGroup,
    InfoWith,
    TopicPageSection,
    TopicPageSectionHeader,
    ListItem,
    TopicPageSectionList,
} from "../../views/content"
import { Markdown } from "../../views/markdown"

export function Head({ data }: PageProps<TopicSinglePageQuery>) {
    const { markdownRemark } = data

    if (!markdownRemark) {
        return null
    }

    const { title, description, path } = markdownRemark

    return (
        <SinglePageHeadLayout
            isHome={false}
            path={path}
            title={title}
            description={description}
            json={[
                jsonBreadcrumbListForSinglePage({
                    kind: Kind.Topic,
                    title,
                    path,
                }),
                jsonTopic(markdownRemark),
            ]}
        />
    )
}

export default function TopicSinglePage({
    data,
}: PageProps<TopicSinglePageQuery>) {
    const { markdownRemark } = data

    if (!markdownRemark) {
        return null
    }

    const {
        title,
        articlesInTopic,
        memosInTopic,
        seriesInTopic,
        html,
        updatedAt,
    } = markdownRemark

    return (
        <SinglePageLayout isHome={false}>
            <ContentHeader>
                <LabelGroup>
                    <KindLabel kind={Kind.Topic} />
                </LabelGroup>
                <h1>{title}</h1>
                <InfoGroup>
                    <InfoWith
                        updatedAt={updatedAt}
                        counts={[
                            {
                                title: localizePlural(Kind.Article),
                                count: articlesInTopic,
                            },
                            {
                                title: localizePlural(Kind.Memo),
                                count: memosInTopic,
                            },
                        ]}
                    />
                </InfoGroup>
            </ContentHeader>
            <Markdown html={html} />
            {!seriesInTopic || !seriesInTopic.length ? null : (
                <TopicPageSection>
                    <TopicPageSectionHeader>
                        <h1>{localizeList(Kind.Series)}</h1>
                    </TopicPageSectionHeader>
                    <TopicPageSectionList>
                        {seriesInTopic.sort(compareByDate).map(item => (
                            <ListItem
                                key={item.path}
                                path={item.path}
                                title={item.title}
                                info={<InfoWith {...item} />}
                            />
                        ))}
                    </TopicPageSectionList>
                </TopicPageSection>
            )}
            {!articlesInTopic || !articlesInTopic.length ? null : (
                <TopicPageSection>
                    <TopicPageSectionHeader>
                        <h1>{localizeList(Kind.Article)}</h1>
                    </TopicPageSectionHeader>
                    <TopicPageSectionList>
                        {articlesInTopic.sort(compareByDate).map(item => (
                            <ListItem
                                key={item.path}
                                path={item.path}
                                title={item.title}
                                info={<InfoWith {...item} />}
                            />
                        ))}
                    </TopicPageSectionList>
                </TopicPageSection>
            )}
            {!memosInTopic || !memosInTopic.length ? null : (
                <TopicPageSection>
                    <TopicPageSectionHeader>
                        <h1>{localizeList(Kind.Memo)}</h1>
                    </TopicPageSectionHeader>
                    <TopicPageSectionList>
                        {memosInTopic.sort(compareByDate).map(item => (
                            <ListItem
                                key={item.path}
                                path={item.path}
                                title={item.title}
                                info={<InfoWith {...item} />}
                            />
                        ))}
                    </TopicPageSectionList>
                </TopicPageSection>
            )}
        </SinglePageLayout>
    )
}

export const pageQuery = graphql`
    query TopicSinglePage($id: String!) {
        markdownRemark(
            frontmatter: { id: { eq: $id } }
            published: { eq: true }
        ) {
            path
            title
            description
            publishedAt
            updatedAt
            html
            seriesInTopic {
                path
                title
                updatedAt
                updatedAtTimestamp
                publishedAt
                publishedAtTimestamp
                topics {
                    path
                    title
                }
            }
            articlesInTopic {
                path
                title
                updatedAt
                updatedAtTimestamp
                publishedAt
                publishedAtTimestamp
                topics {
                    path
                    title
                }
            }
            memosInTopic {
                path
                title
                updatedAt
                updatedAtTimestamp
                publishedAt
                publishedAtTimestamp
                topics {
                    path
                    title
                }
            }
        }
    }
`
